/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ContentModule from '../ContentModule';

export const ModuleIntegrationPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  definition: PropTypes.object.isRequired
};

function ModuleIntegration(props) {
  const { definition } = props || {};
  const { componentId = '' } = definition || {};

  if (!componentId) {
    // eslint-disable-next-line no-console
    console.error(`Component ${componentId} does not exist`);
    return null;
  }
  return <ContentModule {...props} variant={componentId} />;
}
ModuleIntegration.propTypes = ModuleIntegrationPropTypes;

ModuleIntegration.defaultProps = {};

export default ModuleIntegration;
