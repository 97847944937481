import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import MarkdownTable from './MarkdownTable';

export const TablePropTypes = {
  contentString: PropTypes.string,
  customTable: PropTypes.string
};

const CustomTable = (props) => {
  const router = useRouter();
  const { contentString: content, customTable } = props;
  const [loading, setLoading] = useState(false);
  const [customContent, setCustomContent] = useState(content);

  useEffect(() => {
    async function fetchData() {
      if (customTable && customTable === 'Cookies') {
        setLoading(true);
        const temp = [
          '',
          '| Name | Cookies | Expiry | Purpose |',
          '| ---------- | ---------- | ---------- |---------- |'
        ];

        const osanoConfig = (locale) => {
          switch (locale) {
            case 'gb-en':
              return '8e2dd9cf-92e0-470b-bc4b-e1cd33833747'; // prod
            // return '0ca52018-ab9c-48ef-af46-81297555ae35'; // stage
            default:
              return '577ef360-469a-406b-85e9-f7f9395abaf5';
          }
        };

        const url = `https://disclosure.api.osano.com/customer/AzytiGSnWt3QoaRU/config/${osanoConfig(
          router.locale
        )}?language=en`;
        const { data } = await axios.get(url);

        data.forEach((row) => {
          temp.push(
            `| ${row.name || ''} | ${row.provider || ''} | ${row.expiry || ''} | ${
              row.purpose || ''
            } |`
          );
        });
        temp.push('');
        setCustomContent(temp.join('\n'));
        setLoading(false);
      }
    }
    fetchData();
  }, [customTable, router.locale]);

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={500} animation="wave" />
      ) : (
        <MarkdownTable
          content={customContent}
          sx={{
            '& th': {
              fontSize: 14,
              fontWeight: 700
            }
          }}
        />
      )}
    </>
  );
};

CustomTable.propTypes = TablePropTypes;

CustomTable.defaultProps = {
  contentString: null,
  customTable: null
};

export default React.memo(CustomTable);
