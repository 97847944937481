/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import xss from 'xss';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import Box from '@material-ui/core/Box';

import styles from './RichTextParser.module.scss';
import getCardProps from '../../utils/getCardProps';
import Card from '../Card';
import Image from '../Image';
import ElementLink from '../ElementLink';
import ModuleIntegration from '../ModuleIntegration';
import BlockQuote from '../../components-v2/BlockQuote';
import Table from '../../components-v2/Table';
import Media from '../../components-v2/Media';
import sidekickInit from '../../utils/sidekick/init';

const keyBy = (key, xs) => xs.filter(Boolean).reduce((acc, x) => ({ ...acc, [x[key]]: x }), {});

const ElementCard = dynamic(() => import('../ElementCard'));

export const RichTextParserPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.object.isRequired,
  _id: PropTypes.string,
  _contentTypeId: PropTypes.string,
  internalTitle: PropTypes.string,
  listItemVariant: PropTypes.oneOf(['dot', 'check', 'ordered', 'normal'])
};

const bodyXSS = new xss.FilterXSS({
  whiteList: { div: ['id', 'style'], small: [] },
  css: false
  // TODO figure out why css-filter doesnt work
  // css: {
  //   // whitelist: {
  //   //   'scroll-margin-top': true,
  //   //   'scrollMarginTop': true
  //   // },
  //   onAttr: (name,value,options) => {
  //     console.log("Validate", {name,value, options})
  //     return true;
  //   }
  // },
});
const containsHTML = (children) =>
  children?.some((child) => child?.includes && child?.includes('<'));
function RichTextParser({ document = {}, _id, _contentTypeId, internalTitle, listItemVariant }) {
  const { json, links = {} } = document;
  const entries = keyBy('id', links?.entries ?? []);
  const assets = keyBy('id', links?.assets ?? []);
  const { lang } = useTranslation();
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });

  // return (
  //   <pre>
  //     {JSON.stringify(
  //       { document, _id, _contentTypeId, internalTitle, listItemVariant },
  //       null,
  //       2
  //     )}
  //   </pre>
  // );

  if (!json) return null;

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        const { data, content } = node;
        if (data.uri.includes('youtube.com/embed')) {
          return (
            <iframe
              title="Embedded"
              width="560"
              height="315"
              src={data.uri}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; autoplay"
              frameBorder="0"
              allowFullScreen
            />
          );
        }

        return (
          <ElementLink
            href={data.uri}
            target={
              !data?.uri.startsWith('/') && !data?.uri.includes('impossiblefoods.com')
                ? '_blank'
                : false
            }
            className={styles.link}>
            {content[0].value}
          </ElementLink>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const id = node?.data?.target?.sys?.id;
        const target = assets[id];

        if (target.url)
          return (
            <div className="mx-auto text-center">
              <Image image={target} {...sidekicker('Embedded Asset')} />
            </div>
          );
        return null;
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 className="h1">{children}</h1>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className="h2">{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 className="h3">{children}</h3>;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <h4 className="h4">{children}</h4>;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <h5 className="h5">{children}</h5>;
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        return <h6 className="h6">{children}</h6>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const id = node?.data?.target?.sys?.id;
        const target = entries[id];
        const { _contentTypeId: contentTypeId } = target;

        if (contentTypeId === 'moduleIntegration') {
          return <ModuleIntegration {...target} />;
        }

        if (contentTypeId === 'table' && target?.customTable) {
          return <Table contentString={target.contentString} customTable={target.customTable} />;
        }

        if (contentTypeId === 'elementLink') {
          return (
            <Box>
              <ElementLink {...target} {...sidekicker('Embedded Entry')} />
            </Box>
          );
        }

        const cardProps = getCardProps({ card: target, lang });
        const card = {
          ...cardProps,
          variant: 'Info'
        };
        if (card) {
          if (target.cardStyle === 'Media') {
            card.variant = '';
            Object.assign(card, target);
          }
          const aspectRatio =
            target.ratioHorizontal &&
            target.ratioHorizontal &&
            `${target.ratioHorizontal}/${target.ratioVertical}`;
          return (
            <div
              className={`row col-12 ${
                aspectRatio !== '16/9' && 'col-md-7 col-lg-6 col-xl-5'
              } p-0 pb-4 pr-md-5 py-md-4 float-md-left mx-auto mx-md-0 d-table clearfix`}
              {...sidekicker('Embedded Entry')}>
              {target.cardStyle === 'Media' ? <ElementCard {...card} /> : <Card {...card} />}
            </div>
          );
        }
        return null;
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const id = node?.data?.target?.sys?.id;
        const target = entries[id];
        const { _contentTypeId: contentTypeId } = target;
        if (contentTypeId === 'elementLink')
          return <ElementLink {...target} {...sidekicker('Embedded Entry')} />;
        if (contentTypeId === 'card') {
          return target.cardStyle === 'Media' ? (
            <ElementCard {...target} {...sidekicker('Embedded Entry')} />
          ) : (
            <Card {...target} {...sidekicker('Embedded Entry')} />
          );
        }
        if (contentTypeId === 'media')
          return <Media {...target} lazy {...sidekicker('Embedded Entry')} />;
        return null;
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return <BlockQuote>{children}</BlockQuote>;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (containsHTML(children)) {
          return (
            <div
              className={styles.paragraph}
              // We're passing the text through xss which should clean it up for us
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: bodyXSS.process(children) }}
            />
          );
        }
        return <p>{children}</p>;
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <ol>{children}</ol>;
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return listItemVariant !== 'ordered' ? (
          <div
            className={
              listItemVariant === 'normal' ? styles.normalUnorderedList : styles.unorderedList
            }>
            {children}
          </div>
        ) : (
          <ul>{children}</ul>
        );
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        if (listItemVariant === 'ordered') {
          return <li>{children}</li>;
        }

        return (
          <li className={styles.listItemWrapper}>
            <Box
              role="listitem"
              mb={1}
              className={listItemVariant === 'normal' ? styles.normalListItem : styles.listItem}>
              {listItemVariant === 'check' ? (
                <CheckCircleIcon style={{ marginRight: 5, marginTop: -5 }} fontSize="small" />
              ) : null}
              {listItemVariant === 'dot' ? (
                <DotIcon style={{ marginRight: 5, marginTop: -5, fontSize: '0.7rem' }} />
              ) : null}
              {children}
            </Box>
          </li>
        );
      },
      [BLOCKS.TABLE]: (node, children) => (
        <div className={styles.tableContainer}>
          <table>{children}</table>
        </div>
      )
    },
    renderMark: {
      [MARKS.SUPERSCRIPT]: (text) => {
        return <sup className={styles.sup}>{text}</sup>;
      }
    }
  };

  return documentToReactComponents(json, options);
}

RichTextParser.propTypes = RichTextParserPropTypes;
RichTextParser.defaultProps = {
  listItemVariant: 'check'
};

export default RichTextParser;
