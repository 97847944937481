/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useStyles from './Media.styles';
import BackgroundImage from '../../generics/BackgroundImage';
import ImagePropTypes from '../ImagePropTypes';

export const MediaPropTypes = {
  desktop: PropTypes.shape(ImagePropTypes),
  tablet: PropTypes.shape(ImagePropTypes),
  mobile: PropTypes.shape(ImagePropTypes)
};

const Media = ({ desktop, tablet, mobile }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      className={classes.root}
      {...theme.styleOverrides?.Media?.root}
    >
      <Box className={classes.wrapper} {...theme.styleOverrides?.Media?.wrapper}>
        <BackgroundImage image={desktop} imageTablet={tablet} imageMobile={mobile} />
      </Box>
    </Box>
  );
};

Media.propTypes = MediaPropTypes;

Media.defaultProps = {
  desktop: null,
  tablet: null,
  mobile: null
};

export default Media;
