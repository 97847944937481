/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import sidekickInit from '../../utils/sidekick/init';
import Image, { ImagePropTypes } from '../Image';
import ErrorBoundary from '../ErrorBoundary';
import getPath from '../../utils/getPath';
import styles from './Card.module.scss';

export const CardPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  headerText: PropTypes.node,
  subhead: PropTypes.node,
  supportingText: PropTypes.node,
  media: PropTypes.shape(ImagePropTypes),
  url: PropTypes.string,
  actions: PropTypes.node,
  variant: PropTypes.oneOf([
    '',
    'icon',
    'Text',
    'Info',
    'Profile',
    'three-row',
    'featured',
    'Timeline'
  ]),
  cardNumber: PropTypes.oneOf(['card1', 'card2', 'card3']),
  imageAlign: PropTypes.oneOf(['', 'left', 'right'])
};

function Card({
  _id,
  _contentTypeId,
  internalTitle,
  headerText,
  subhead,
  supportingText,
  media,
  url = null,
  actions = null,
  variant = '',
  cardNumber = '',
  imageAlign = ''
}) {
  const { lang } = useTranslation();
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });
  let CardWrapper = 'div';
  const style = {};
  if (url) CardWrapper = 'a';
  if (variant === 'Info' || variant === 'Profile') {
    // TODO: Migrate to use a Image component on the Card itself instead
    style.backgroundImage = media && `url(${media.url})`;
  }

  const cardContent = (
    <CardWrapper
      className={cx(styles.card, styles[variant], styles[cardNumber], styles[imageAlign])}
      data-testid="Card"
      aria-label={`Recipe for ${headerText}`}
      style={style}
      {...sidekicker('Card')}
    >
      {variant !== 'Info' && variant !== 'Profile' && (
        <div className={styles.cardImg}>
          {media && <Image testId="Card-image" image={media} {...sidekicker('Card Image')} />}
        </div>
      )}
      <div className={cx(styles.cardBody)}>
        {actions && (
          <div className={styles.action} data-testid="Card-action" {...sidekicker('Actions')}>
            {actions}
          </div>
        )}
        {headerText && (
          <h3 data-testid="Card-headerText" {...sidekicker('Header Text')}>
            {headerText}
          </h3>
        )}
        {subhead && (
          <div
            className={cx(styles.cardContent, 'd-flex', variant === 'Profile' && 'h4')}
            data-testid="Card-subhead"
            {...sidekicker('Subhead')}
          >
            {subhead}
          </div>
        )}
        {supportingText && (
          <ul
            className={cx(
              styles.cardContent,
              styles.supportingText,
              'd-flex flex-wrap list-unstyled'
            )}
            data-testid="Card-supportingText"
            {...sidekicker('Supporting Text')}
          >
            {supportingText}
          </ul>
        )}
      </div>
    </CardWrapper>
  );

  if (url)
    return (
      <ErrorBoundary>
        <Link href={getPath({ path: url, locale: lang })} legacyBehavior>
          {cardContent}
        </Link>
      </ErrorBoundary>
    );

  return <ErrorBoundary>{cardContent}</ErrorBoundary>;
}

Card.propTypes = CardPropTypes;

Card.defaultProps = {
  headerText: '',
  subhead: '',
  supportingText: '',
  media: null,
  url: '',
  actions: null,
  variant: '',
  cardNumber: null,
  imageAlign: ''
};

export default Card;
