import getPath from './getPath';

const supportedContents = { card: true, pageBlog: true, pageRecipe: true };

const getCardType = ({ _contentTypeId, linkedContent }) => {
  const contentTypeId = linkedContent ? linkedContent._contentTypeId : _contentTypeId;
  if (!supportedContents[contentTypeId]) throw Error('Invalid Content Type');
  return contentTypeId;
};

const getHeaderText = ({ title, headerText, linkedContent }) => {
  const text = (linkedContent || {}).title || title;
  return headerText || text; // headerText defined in Card should override headerText coming from linkedContent
};

const getMedia = ({ image, media, linkedContent }) => {
  return image || media || (linkedContent || {}).image || (linkedContent || {}).media; // image defined in Card should override image coming from linkedContent
};

const getSlug = ({ slug, url, linkedContent }, _contentTypeId, lang) => {
  const href = (linkedContent || {}).slug || slug;
  let path;
  if (href && _contentTypeId === 'pageRecipe') path = `/recipes/${href}`;
  if (href && _contentTypeId === 'pageBlog') path = `/blog/${href}`;
  if (url || path) return getPath({ path: url || path, locale: lang }); // url defined in Card should override slug coming from linkedContent
  return undefined;
};

const getCardProps = ({ card, lang }) => {
  try {
    const { _id } = card;
    const _contentTypeId = getCardType(card);
    const headerText = getHeaderText(card);
    const media = getMedia(card);
    const url = getSlug(card, _contentTypeId, lang);
    return { key: _id, _id, _contentTypeId, headerText, media, url };
  } catch (err) {
    return null;
  }
};

export default getCardProps;
