import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    ...theme.overrides?.Media?.root,
    '& div': {
      position: 'relative !important',
      ...theme.overrides?.Media?.imageWrapper
    },
    '& img': {
      position: 'relative !important',
      width: '100% !important',
      height: '100% !important',
      objectFit: 'cover',
      ...theme.overrides?.Media?.image
    }
  },
  wrapper: {
    ...theme.overrides?.Media?.wrapper
  }
}));
