/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ErrorBoundary from '../../components/ErrorBoundary';
import Image, { ImagePropTypes } from '../../components/Image';
import useWindowSize from '../../utils/hooks/useWindowSize';

export const BackgroundImagePropTypes = {
  image: PropTypes.shape(ImagePropTypes).isRequired,
  imageTablet: PropTypes.shape(ImagePropTypes),
  imageMobile: PropTypes.shape(ImagePropTypes),
  maxWidth: PropTypes.number,
  backgroundColor: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  priority: PropTypes.bool
};

const isVideoBg = (image) => {
  const imgFileExt = image?.url?.split('.').pop();
  return ['mp4', 'mov', 'webm'].includes(imgFileExt);
};

/**
 * Background accepts a video for desktop and mobile devices
 */
const getBgVideo = (desktop, mobile, testId) => {
  const videoRef = React.useRef(null);
  const size = useWindowSize();
  const isMobile = size.width < 768;
  const url = isMobile ? mobile.url : desktop.url;

  React.useEffect(() => {
    // Open bug (since 2017) that `muted` attribute cannot be set in video element
    // https://github.com/facebook/react/issues/10389
    // This at least adds the `muted` attribute so that if they ever fix it, it will be muted
    if (!videoRef.current) return;
    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;
    videoRef.current.src = url;
  }, [url]);

  return [desktop, mobile].filter(isVideoBg).map((video) => (
    <div
      style={{
        display:
          (isMobile && video === mobile) || (!isMobile && video === desktop) ? 'block' : 'none'
      }}
    >
      <BgVideo
        ref={videoRef}
        muted
        loop
        autoPlay
        preload="auto"
        playsInline
        src={url}
        data-src={url}
        data-testid={`${testId || 'BackgroundImage'}-${video === mobile ? 'mobile' : 'desktop'}`}
      />
    </div>
  ));
};

const getArtDirectedImage = (
  dataTestId,
  image,
  imageMobile,
  imageTablet,
  priority,
  sizes,
  maxWidth
) => {
  const spacer = {
    url: 'https://images.ctfassets.net/hhv516v5f7sj/6d7p1PaDZ8Fk9sKToKxUuR/485ec53ab89700182e473c4c99591693/Spacer.gif',
    width: 1,
    height: 1
  };
  const imgDesktop = isVideoBg(image) ? spacer : image;
  const imgMobile = isVideoBg(imageMobile) ? spacer : imageMobile;
  return (
    <ArtDirectedImage
      dataTestId={dataTestId}
      image={imgDesktop}
      imageTablet={imageTablet}
      imageMobile={imgMobile}
      deviceSizes={sizes.filter((x) => x < maxWidth)}
      alt={image?.description || ''}
      priority={priority}
    />
  );
};

function BackgroundImage({
  image,
  imageTablet,
  imageMobile,
  backgroundColor,
  maxWidth,
  sizes = [1024, 1280, 1600, 1920, 2240, 2560, 2880, 3200, 3520, 3840],
  className,
  priority,
  dataTestId
}) {
  return (
    <ErrorBoundary>
      <BgWrap backgroundColor={backgroundColor} className={className}>
        {isVideoBg(image) || isVideoBg(imageMobile)
          ? getBgVideo(image, imageMobile, dataTestId)
          : null}
        {getArtDirectedImage(
          dataTestId,
          image,
          imageMobile,
          imageTablet,
          priority,
          sizes,
          maxWidth
        )}
      </BgWrap>
    </ErrorBoundary>
  );
}

const protocol = (url) => {
  if (!/^((http|https):\/\/)/.test(url)) {
    return `https:${url}`;
  }
  return url;
};

const getDesktopDisplayMedia = (imageTablet, imageMobile) => {
  const mobileMediaQuery = imageMobile?.url ? '(min-width: 768px)' : '';
  return `${imageTablet?.url ? '(min-width: 1024px)' : mobileMediaQuery}`;
};

const getTabletDisplayMedia = (imageMobile) => {
  return imageMobile?.url
    ? '(min-width: 768px) and (max-width: 1023.9px)'
    : '(max-width: 1023.9px)';
};

const ArtDirectedImage = ({ dataTestId, image, imageMobile, imageTablet, className, priority }) => {
  return (
    <>
      {imageMobile?.url ? (
        <ResponsiveImage
          displaymedia="(max-width: 767.9px)"
          sizes="(min-width: 768px) 768px, 100vw"
          maxwidth={767.9}
          media="(max-width: 767.9px)"
          src={protocol(imageMobile?.url)}
          width={imageMobile.width}
          height={imageMobile.height}
          alt={image?.description || ''}
          testId={`${dataTestId || 'BackgroundImage'}-mobile`}
          className={className}
          priority={priority}
        />
      ) : null}
      {imageTablet?.url ? (
        <ResponsiveImage
          displaymedia={getTabletDisplayMedia(imageMobile)}
          maxwidth={1023.9}
          sizes="(min-width: 1024px) 1024px, 100vw"
          media="(min-width: 768px) and (max-width: 1023.9px)"
          src={protocol(imageTablet?.url)}
          width={imageTablet.width}
          height={imageTablet.height}
          alt={image?.description || ''}
          testId={`${dataTestId || 'BackgroundImage'}-tablet`}
          className={className}
          priority={priority && !!imageMobile?.url}
        />
      ) : null}
      {image?.url ? (
        <ResponsiveImage
          sizes="100vw"
          displaymedia={getDesktopDisplayMedia(imageTablet, imageMobile)}
          src={protocol(image?.url)}
          width={image.width}
          height={image.height}
          media="(min-width: 1024px)"
          alt={image?.description || ''}
          testId={`${dataTestId || 'BackgroundImage'}-desktop`}
          className={className}
          priority={priority && !!imageMobile?.url}
        />
      ) : null}
    </>
  );
};

const ResponsiveImage = styled(Image)`
  height: 100%;
  width: 100%;
  ${({ displaymedia }) =>
    displaymedia
      ? css`
          display: none !important;
          img {
            display: none !important;
            visibility: hidden !important;
          }
          @media ${displaymedia} {
            display: block !important;
            visibility: visible !important;
            img {
              display: block !important;
              visibility: visible !important;
            }
          }
        `
      : ''}
`;

const bgColor = `${({ backgroundColor }) => `background-color:${backgroundColor};`}`;

const BgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  img {
    object-fit: cover;
  }
  ${bgColor}
`;

const BgVideo = styled.video`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

BackgroundImage.propTypes = BackgroundImagePropTypes;
BackgroundImage.defaultProps = {
  imageTablet: null,
  imageMobile: null,
  maxWidth: Infinity,
  backgroundColor: 'transparent',
  className: null,
  sizes: [3840, 3520, 3200, 2880, 2560, 2240, 1920, 1600, 1440, 1280, 960, 640],
  priority: false,
  dataTestId: null
};

export default React.memo(BackgroundImage);
