import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      '& td': {
        'fontSize': 12,
        '&:first-child': {
          fontSize: 12
        }
      }
    }
  }
}));
