/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import xss from 'xss';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import ErrorBoundary from '../../components/ErrorBoundary';
import useStyles from './BlockQuote.styles';

export const BlockQuotePropTypes = {
  children: PropTypes.element.isRequired
};

const bodyXSS = new xss.FilterXSS({
  whiteList: { div: ['id', 'style'] },
  css: false
});
const containsHTML = (children) =>
  children?.some((child) => child.includes && child?.includes('<'));

function BlockQuote({ children }) {
  const classes = useStyles();

  return (
    <ErrorBoundary>
      <div className={classes.root} data-testid="BlockQuote">
        <FormatQuoteIcon className={classes.quoteIcon} />
        {containsHTML(children) && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: bodyXSS.process(children) }}
          />
        )}
        <div>{children}</div>
      </div>
    </ErrorBoundary>
  );
}

BlockQuote.propTypes = BlockQuotePropTypes;

BlockQuote.defaultProps = {};

export default React.memo(BlockQuote);
