/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ErrorBoundary from '../../components/ErrorBoundary';
import useStyles from './Table.styles';

export const TablePropTypes = {
  content: PropTypes.string
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.extra.mercury,
    fontSize: 14,
    fontWeight: 'bold'
  },
  body: {
    'overflow': 'hidden',
    'fontSize': 14,
    // Name
    '&:first-child': {
      backgroundColor: theme.palette.extra['extra-light-grey'],
      fontFamily: 'monospace',
      fontSize: 13
    },
    // Expiry
    '&:nth-child(3)': {
      whiteSpace: 'nowrap'
    }
  }
}))(TableCell);

function MarkdownTable({ content }) {
  const classes = useStyles();

  const { header, lines } = useMemo(() => {
    const tmp = content.split('\n');
    const headerCells = tmp[1].split('|');
    const headerData = headerCells.slice(1, tmp[0].length - 1);
    const linesData = tmp.slice(3).map((line) => {
      const cells = line.split('|');
      return cells.slice(1, cells.length - 1);
    });
    return { header: headerData, lines: linesData };
  }, [content]);

  return (
    <ErrorBoundary>
      <TableContainer className={classes.root} data-testid="Table">
        <Table size="small">
          <TableHead>
            <TableRow>
              {header.map((h) => (
                <StyledTableCell key={h}>{h}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map((line) => (
              <TableRow key={line.join('|')}>
                {line.map((cell) => (
                  <StyledTableCell key={cell}>{cell}</StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ErrorBoundary>
  );
}

MarkdownTable.propTypes = TablePropTypes;

MarkdownTable.defaultProps = {
  content: null
};

export default React.memo(MarkdownTable);
